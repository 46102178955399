import React from "react"
import { Link } from "gatsby"
import { SectionTitle, SectionTitle2 } from "../components/styled"
import WrapperRight from "../components/WrapperRight"
import Contact from "../components/Contact"
import { Seo } from "../components/seo"
const ToResidentsJapanOtherCountries = () => {
  return (
    <>
      <section className="container to-residents-mexico">
        <div className="row">
          <div className="col-lg-9 col-lg-9">
            <div className="to-residents-mexico__for-to-residents-mexico">
              <SectionTitle>日本及びその他の国にお住まいの方へ</SectionTitle>
              <hr className="u-line-bottom" />
              <div className="to-residents-mexico__for-to-residents-mexico--box">
                <p>
                  クイックグローバルメキシコはメキシコのアグアスカリエンテス州を拠点に、メキシコ各地の日系企業でのお仕事を専門に紹介している日系人材紹介会社です。
                  メキシコには約1,200社の日系企業が進出しており、特に日本語・スペイン語スキルがある人材が求められています。業務経験や専門スキルをお持ちの場合は英語のみで応募可能な求人、また、新卒・未経験から応募可能な求人もございますので、まずは一度お問い合わせください。
                  <br />
                  弊社でのサポートの流れは「
                  <Link to="/process">
                    お仕事紹介の流れ
                  </Link> 」をご覧ください。 <br />
                  <small>※弊社サービスのご利用は無料です。</small>
                </p>
              </div>
              <div className="to-residents-mexico__for-to-residents-mexico">
                <SectionTitle2>
                  メキシコで働くとどんなメリットがあるの？{" "}
                </SectionTitle2>
                <hr className="u-line-bottom" />
                <div className="to-residents-mexico__for-to-residents-mexico--box">
                  <ul className="to-residents-mexico__list">
                    <li> 新興国での経済発展を体感できる</li>
                    <li>
                      スペイン語を日常生活・仕事で活用でき、語学スキルを磨ける
                    </li>
                    <li>進出まもない企業で中核人材として働くことが可能</li>
                    <li>日本では就職が難しい大手企業に入れる可能性がある</li>
                    <li>物価が安く生活しやすい</li>
                  </ul>
                </div>
              </div>

              <div className="to-residents-mexico__for-to-residents-mexico">
                <SectionTitle2>
                  クイックグローバルメキシコを使うメリットは？
                </SectionTitle2>
                <hr className="u-line-bottom" />
                <div className="to-residents-mexico__for-to-residents-mexico--box">
                  <ul className="to-residents-mexico__list">
                    <li> メキシコの日系企業求人の検索ができる</li>
                    <li>自分の経歴に合った仕事を紹介してもらえる</li>
                    <li>数ある求人の中から好きな場所・職を選べる</li>
                    <li>必要に応じて代わりに企業と交渉してくれる</li>
                    <li>メキシコ就職する上でのアドバイスがもらえる</li>
                  </ul>
                </div>
              </div>

              <div className="to-residents-mexico__for-to-residents-mexico">
                <SectionTitle2>どういった方にオススメ？</SectionTitle2>
                <hr className="u-line-bottom" />
                <div className="to-residents-mexico__for-to-residents-mexico--box">
                  <ul className="to-residents-mexico__list">
                    <li> 海外で転職・就職したい</li>
                    <li>スペイン語を使用する仕事を探している</li>
                    <li>スペイン語学科を卒業・卒業予定</li>
                    <li>
                      日本である程度経験も積み、今後のステップアップを考え海外で挑戦したい
                    </li>
                    <li>日本でスペイン語に関わる仕事に就いた経験がある</li>
                  </ul>
                  <hr className="u-line-bottom" />
                  <p>
                    メキシコでお仕事をお探しなら、現地に精通したコンサルタントがお答えします。
                    まずはお気軽にお問い合わせください。
                  </p>
                  <a
                    className="job-button"
                    href="https://www.919mexico.com/job-form/"
                  >
                    <div className="arrow right"></div>
                    <span className="button-text">今すぐ無料相談</span>
                  </a>
                </div>
              </div>

              <Contact lang={"ja"} />
            </div>
          </div>
          <WrapperRight />
        </div>
      </section>
    </>
  )
}

export default ToResidentsJapanOtherCountries

export const Head = () => {
  return (
    <Seo
      title="日本及びその他の国にお住まいの方へ"
      description="メキシコの求人情報の掲載、就職・転職サポートを行う転職エージェントサービス。メキシコ国内の転職だけでなく、日本からの転職もサポート。"
      url={`https://www.919mexico.com/to-residents-in-japan-and-other-countries`}
    />
  )
}
